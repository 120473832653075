import {Component, OnInit} from "@angular/core";
import {UserService} from "@dashboard/src/app/service/user.service";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {environment} from "@dashboard/src/environments/environment";
import {ObjectItem} from "@protocol/types/ObjectItem";
import {ObjectEffectInteger} from "@protocol/types/ObjectEffectInteger";
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
  selector: "app-bot-inventory-character",
  templateUrl: "./bot-inventory-character.component.html",
  styleUrls: ["./bot-inventory-character.component.scss"]
})
export class BotInventoryCharacterComponent implements OnInit {
  public bot: Bot;
  public pathImages = environment.HTTP_SERVER_ADDRESS + "/images/items/";

  constructor(private userService: UserService, private clipboard: Clipboard
  ) {
    this.bot = userService.botSelected;
  }

  ngOnInit(): void {
  }

  getElement(position: number): any {
    return this.bot.game.character.inventory.objects.find(elt => elt.position == position);
  }

  copyEffects(item: any) {
    const effects: any = [];
    for (const effect of item["_possibleEffects"] || []) {
      const itemEffect = item.effects.find((elt: any) =>
        elt.actionId == effect.effectId && elt._messageType == "ObjectEffectInteger") as ObjectEffectInteger;
      const itemEffectValue = itemEffect?.value || 0;
      effects.push({
        id: effect.effectId,
        min: effect.diceNum,
        max: effect.diceSide,
        value: itemEffectValue,
        operator: effect["_effectData"].operator,
        description: effect["_effectData"].description,
        name: effect["_nameChara"],
        isExtraEffect: false,
      });
    }
    for (const effect of item.effects.filter(
      (elt: any) => elt._messageType == "ObjectEffectInteger") as ObjectEffectInteger[]) {
      // @ts-ignore
      const effectData = effect["_effectData"];
      if (effects.findIndex((elt: any) => elt.id == effect.actionId) == -1) {
        // @ts-ignore
        const name = effect["_nameChara"];
        effects.push({
          id: effect.actionId,
          min: -1,
          max: -1,
          value: effect.value,
          operator: effectData.operator,
          description: effectData.description,
          name,
          isExtraEffect: true
        });
      }
    }
    for (const effect of effects) {
      if (!(effect.name)) {
        effect.text = effect.description;
      } else {
        effect.text = `${effect.operator} ${effect.value} ${effect.name} `;
        if (!effect.isExtraEffect) {
          effect.text += effect.min != -1 && effect.max != -1 ? `( ${effect.operator}${effect.min} à ${effect.operator}${effect.max} )` : ``;
        }
      }
    }
    let clip = "";
    for (const effect of effects) {
      if (!effect.name) continue;
      if (effect.operator == "+") {
        clip += `{"name": "${effect.name}", "op": "${effect.value > 0 ? ">=" : "=="}", "value": ${effect.value}},` + "\n";
      } else {
        clip += `{"name": "${effect.name}", "op": "${effect.value > 0 ? "<=" : "=="}", "value": ${effect.value}},` + "\n";
      }
    }

    console.log("Copy : \n" + clip);
    this.clipboard.copy(clip);
  }
}

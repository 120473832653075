import {Component, OnDestroy, OnInit} from "@angular/core";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {environment} from "@dashboard/src/environments/environment";
import {UserService} from "@dashboard/src/app/service/user.service";
import {ObjectItem} from "@protocol/types/ObjectItem";
import {CustomWrapper} from "@common/lib/utils/CustomDispatcher";
import {ObjectEffectInteger} from "@protocol/types/ObjectEffectInteger";
import {ComponentTypeSelectedEnum} from "@dashboard/src/app/enums/ComponentTypeSelectedEnum";

@Component({
  selector: "app-bot-fm-effects",
  templateUrl: "./bot-fm-effects.component.html",
  styleUrls: ["./bot-fm-effects.component.scss"]
})
export class BotFmEffectsComponent implements OnInit, OnDestroy {
  public bot: Bot;
  public pathImages = `${environment.HTTP_SERVER_ADDRESS}/images/items/`;
  public actualItem: ObjectItem;
  public actualRune: ObjectItem;
  public effects: {
    id: number,
    min: number,
    max: number,
    value: number,
    name: string,
    modif?: string,
    si: ObjectItem
    pa: ObjectItem,
    ra: ObjectItem,
    isExtraEffect: boolean,
    backgroundcolor?: string
  }[] = [];
  protected readonly ComponentTypeSelectedEnum = ComponentTypeSelectedEnum;
  private wrapper: CustomWrapper;

  constructor(private userService: UserService) {
    this.bot = this.userService.botSelected;
  }

  ngOnInit(): void {
    this.wrapper = this.bot.wrap();
    this.wrapper.addMonitorPacket([
      "ExchangeObjectModifiedMessage",
      "ExchangeObjectAddedMessage",
      "ExchangeContentMessage",
      "ExchangeObjectModifiedMessage",
      "ObjectModifiedMessage"
    ], () => {
      this.updateData();
    }, 2000);
    this.updateData();
  }

  ngOnDestroy(): void {
    this.wrapper.done();
  }

  getRune(actionId: number, quality: number) {
    for (const rune of this.bot.game.character.inventory.getObjectsByTypeId(78)) {
      // @ts-ignore
      const name = rune["_name"];
      for (const effect of rune.effects) {
        if (effect.actionId == actionId) {
          if (quality == 0 && name.indexOf("Pa") == -1 && name.indexOf("Ra") == -1) {
            return rune;
          } else if (quality == 1 && name.indexOf("Pa") != -1) {
            return rune;
          } else if (quality == 2 && name.indexOf("Ra") != -1) {
            return rune;
          }
        }
      }
    }
    return null;
  }

  private updateData() {
    // @ts-ignore
    this.actualItem = this.bot.game.exchange.listExchangeItems.find(elt => elt["_typeId"] !== 78);
    // @ts-ignore
    this.actualRune = this.bot.game.exchange.listExchangeItems.find(elt => elt["_typeId"] == 78);
    this.effects = [];
    if (this.actualItem) {
      // @ts-ignore
      for (const effect of this.actualItem["_possibleEffects"]) {
        const itemEffect = this.actualItem.effects.find(elt =>
          elt.actionId == effect.effectId && elt._messageType == "ObjectEffectInteger") as ObjectEffectInteger;
        const itemEffectValue = itemEffect?.value || 0;
        this.effects.push({
          id: effect.effectId,
          min: effect.diceNum,
          max: effect.diceSide,
          value: itemEffectValue,
          name: effect["_nameChara"],
          si: this.getRune(effect.effectId, 0),
          pa: this.getRune(effect.effectId, 1),
          ra: this.getRune(effect.effectId, 2),
          isExtraEffect: false,
        });
      }
      for (const effect of this.actualItem.effects.filter(
        elt => elt._messageType == "ObjectEffectInteger") as ObjectEffectInteger[]) {
        if (this.effects.findIndex(elt => elt.id == effect.actionId) == -1) {
          // @ts-ignore
          const name = effect["_nameChara"];
          // @ts-ignore
          const effectData = effect["_effectData"];
          this.effects.push({
            id: effect.actionId,
            min: -1,
            max: -1,
            value: effect.value,
            name: name || effectData.description,
            si: this.getRune(effect.actionId, 0),
            pa: this.getRune(effect.actionId, 1),
            ra: this.getRune(effect.actionId, 2),
            isExtraEffect: true
          });
        }
      }
      const lastFm = this.bot.fm.logs.length > 0 ? this.bot.fm.logs[this.bot.fm.logs.length - 1] : null;
      if (lastFm) {
        for (const effect of this.effects) {
          const modif = lastFm.modifs.find(elt => elt.effectId == effect.id);
          if (modif) {
            effect.modif = modif.value > 0 ? `+${modif.value}` : `${modif.value}`;
            effect.backgroundcolor = modif.value > 0 ? "#2f4918" : "#532527";
          }
        }
      }
    }
  }
}

import {Component, Input, OnInit} from "@angular/core";
import {ObjectEffectInteger} from "@protocol/types/ObjectEffectInteger";
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
  selector: "app-item-effects",
  templateUrl: "./item-effects.component.html",
  styleUrls: ["./item-effects.component.scss"]
})
export class ItemEffectsComponent implements OnInit {
  @Input() public item: any;

  public effects: {
    id: number,
    min: number,
    max: number,
    value: number,
    name: string,
    operator: string,
    description: string,
    isExtraEffect: boolean,
    text?: string
  }[] = [];

  constructor(private clipboard: Clipboard
  ) {
  }

  ngOnInit(): void {
    this.effects = [];
    for (const effect of this.item["_possibleEffects"] || []) {
      const itemEffect = this.item.effects.find((elt: any) =>
        elt.actionId == effect.effectId && elt._messageType == "ObjectEffectInteger") as ObjectEffectInteger;
      const itemEffectValue = itemEffect?.value || 0;
      this.effects.push({
        id: effect.effectId,
        min: effect.diceNum,
        max: effect.diceSide,
        value: itemEffectValue,
        operator: effect["_effectData"].operator,
        description: effect["_effectData"].description,
        name: effect["_nameChara"],
        isExtraEffect: false,
      });
    }
    for (const effect of this.item.effects.filter(
      (elt: any) => elt._messageType == "ObjectEffectInteger") as ObjectEffectInteger[]) {
      // @ts-ignore
      const effectData = effect["_effectData"];
      if (this.effects.findIndex(elt => elt.id == effect.actionId) == -1) {
        // @ts-ignore
        const name = effect["_nameChara"];
        this.effects.push({
          id: effect.actionId,
          min: -1,
          max: -1,
          value: effect.value,
          operator: effectData.operator,
          description: effectData.description,
          name,
          isExtraEffect: true
        });
      }
    }
    for (const effect of this.effects) {
      if (!(effect.name)) {
        effect.text = effect.description;
      } else {
        effect.text = `${effect.operator} ${effect.value} ${effect.name} `;
        if (!effect.isExtraEffect) {
          effect.text += effect.min != -1 && effect.max != -1 ? `( ${effect.operator}${effect.min} à ${effect.operator}${effect.max} )` : ``;
        }
      }
    }
    let clip = "";
    for (const effect of this.effects) {
      if (!effect.name) continue;
      if (effect.operator == "+") {
        clip += `{"name": "${effect.name}", "op": "${effect.value > 0 ? ">=" : "=="}", "value": ${effect.value}},` + "\n";
      } else {
        clip += `{"name": "${effect.name}", "op": "${effect.value > 0 ? "<=" : "=="}", "value": ${effect.value}},` + "\n";
      }
    }

    console.log("Copy : \n" + clip);
    this.clipboard.copy(clip);
  }
}

<button (click)="bot.simplePather.updateOnBreak(!bot.simplePather.onBreak)"
        [style.color]="bot.simplePather.onBreak ? 'green' : 'red'"
        class="start-stop" mat-icon-button>
  <mat-icon>{{ bot.simplePather.onBreak ? 'play_arrow' : 'pause' }}</mat-icon>
</button>
<div>
  {{ bot.game.daysRemaining.toFixed(2) }} jour's restant
</div>
<div>
  Classe : {{ getBreedNameById(bot?.game?.character?.breed) }} ({{ bot?.game?.character?.breed }})
</div>
<div>
  {{ bot.game.subscriptionEndDate | date }} jour's restant
</div>

<div
  [ngClass]="{'selected': userService.teamSelected?.id === team.id && userService.componentSelected === ComponentTypeSelectedEnum.TEAM}"
  class="team-infos">
  <button (click)="clickOnTeam($event)"
          (contextmenu)="clickOnContextMenu($event)" class="close-open-team" mat-button>
    {{ team.name }}
  </button>

  <!--  <div cdkDragHandle class="icon-handle">-->
  <!--    <svg fill="currentColor" viewBox="0 0 24 24">-->
  <!--      <path-->
  <!--        d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>-->
  <!--      <path d="M0 0h24v24H0z" fill="none"></path>-->
  <!--    </svg>-->
  <!--  </div>-->

  <mat-icon *ngIf="team.chef && team.chef.game.character.state.context == 2"
            class="team-state-fight mat-elevation-z8">
    local_dining
  </mat-icon>
  <div *ngIf="team.bots.length > 0" [style.background-color]="connectedStateColor" class="state mat-elevation-z8"></div>

  <mat-icon *ngIf="team.bots.length > 0 && team.bots[0].accountState && team.bots[0].accountState != 'NONE'"
            [className]="'account-state account-state-' + team.bots[0].accountState"
            class=" mat-elevation-z8">
    {{ team.bots[0].accountState == 'GOOD' ? "thumb_up" : "thumb_down" }}
  </mat-icon>


  <mat-icon (click)="editComment()" *ngIf="team.config.comment" class="comment mat-elevation-z8">
    email
  </mat-icon>


  <div *ngIf="team.chef" class="position">
  </div>
  <div class="kamas">
    {{ (team.kamas | number) }}
  </div>
  <div [style.color]="teamAbo ? 'green':'red'" class="left-top">
    {{ team.bots.length }}
  </div>
  <div class="lastWinFightDate">
    {{ diffLastFight() }}
  </div>
  <button (click)="clickOnContextMenu($event)" class="left-button" mat-button></button>
  <button
    (click)="selectThisTeam()"
    cdkDragHandle class="route-button"
    mat-button></button>
</div>
<span *ngIf="team._open" class="team-open">
  <div (cdkDropListDropped)="dropBot($event)"
       [cdkDropListConnectedTo]="LIST_IDS"
       [cdkDropListData]="team.bots"
       [id]="team.id"
       cdkDropList
       class="example-list">

    <div *ngFor="let bot of team.bots" [cdkDragData]="bot" cdkDrag>
      <div *cdkDragPlaceholder class="example-custom-placeholder"></div>
      <app-bot-element [bot]="bot"></app-bot-element>
    </div>
  </div>
</span>

<div [matMenuTriggerFor]="contextMenu"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     style="visibility: hidden; position: fixed">
</div>

<mat-menu #contextMenu="matMenu">
  <ng-template let-item="item" matMenuContent>
    <button (click)="connectBots()" mat-menu-item>Connect/Reconnect Bots</button>
    <button (click)="connectBotsDisconnected()" mat-menu-item>Connect Bots Disconnected</button>
    <button (click)="disconnectBots()" mat-menu-item>Disconnect Bots</button>
    <button (click)="createFromConfig()" mat-menu-item>Create Characters From Config</button>
    <mat-divider></mat-divider>
    <button (click)="addBot()" mat-menu-item>Add Bot</button>
    <button (click)="addBotFromText()" mat-menu-item>Add Bots from text</button>
    <button (click)="addBotsFromBDD()" mat-menu-item>Add accounts ({{ 8 - team.bots.length }}) from BDD</button>
    <mat-divider></mat-divider>
    <button (click)="rename()" mat-menu-item>Rename</button>
    <button (click)="editComment()" mat-menu-item>Edit Comment</button>
    <mat-divider></mat-divider>
    <button (click)="copyIds()" mat-menu-item>Copies IDs</button>
    <button (click)="copyIdsAndRemove()" class="action-dangerous" mat-menu-item>Copies IDs and Remove</button>
    <mat-divider></mat-divider>
    <button (click)="removeTeam(false)" class="action-dangerous" mat-menu-item>Remove Team</button>
    <button (click)="removeTeam(true)" class="action-dangerous" mat-menu-item>Team + Bots</button>
    <button (click)="removeBots()" class="action-dangerous" mat-menu-item>Just Bots</button>
    <button (click)="removeAndSaveBots()" class="action-dangerous" mat-menu-item>Remove and save</button>
    <mat-divider></mat-divider>
    <button (click)="setState('LOST')" mat-menu-item>Set LOST</button>
    <button (click)="setState('GOOD')" mat-menu-item>Set GOOD</button>
    <button (click)="setState('NONE')" mat-menu-item>Set NONE</button>
  </ng-template>
</mat-menu>


import {Component, HostListener, Input, OnInit, ViewChild} from "@angular/core";
import {MatMenuTrigger} from "@angular/material/menu";
import {UserService} from "@dashboard/src/app/service/user.service";
import {ComponentTypeSelectedEnum} from "@dashboard/src/app/enums/ComponentTypeSelectedEnum";
import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {Team} from "@dashboard/src/app/service/user/controllers/controller/team";
import {
  AddBotInstanceDialogComponent
} from "@dashboard/src/app/features/sidenav/controller-content/add-bot-instance-dialog/add-bot-instance-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {DialogQuestionComponent} from "@dashboard/src/app/features/dialog-question/dialog-question.component";
import {Clipboard} from "@angular/cdk/clipboard";
import {ActivatedRoute, Router} from "@angular/router";
import {Utils} from "@common/lib/utils";

@Component({
  selector: "app-team-element",
  templateUrl: "./team-element.component.html",
  styleUrls: ["./team-element.component.scss"]
})
export class TeamElementComponent implements OnInit {
  public ComponentTypeSelectedEnum = ComponentTypeSelectedEnum;
  @Input() team: Team;

  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  public contextMenuPosition = {x: "0px", y: "0px"};

  public isDragging = false;
  public pressTimer: any;

  constructor(
    public dialog: MatDialog,
    private clipboard: Clipboard,
    private route: ActivatedRoute,
    private router: Router,
    public userService: UserService
  ) {
  }

  public get teamAbo() {
    for (const bot of this.team.bots) {
      if (isNaN(bot.game.daysRemaining) || bot.game.daysRemaining == 0) {
        return false;
      }
    }
    return true;
  }

  public get connectedStateColor(): string {
    const botsConnected = this.team.bots.filter(elt => elt.network.connected);
    if (botsConnected.length == this.team.bots.length) {
      return "green";
    } else if (botsConnected.length == 0) {
      return "red";
    } else {
      return "orange";
    }
  }

  public get LIST_IDS(): string[] {
    return ["solo", ...this.team.controller.teams.map(elt => elt.id)];
  }

  clickOnContextMenu(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + "px";
    this.contextMenuPosition.y = event.clientY + "px";
    this.contextMenu.menu.focusFirstItem("mouse");
    this.contextMenu.openMenu();
  }

  ngOnInit(): void {
  }

  dropBot(event: CdkDragDrop<Bot[]>) {
    if (event.previousContainer === event.container) {
      this.team.moveBotInArray(event.previousIndex, event.currentIndex);
    } else {
      const sourceTeamId = event.previousContainer.id;
      const targetTeamId = event.container.id;
      const previousIndex = event.previousIndex;
      const currentIndex = event.currentIndex;
      this.team.controller.moveBotBetweenMultipleTeam(sourceTeamId, targetTeamId, previousIndex, currentIndex);
    }
  }

  addBot() {
    this.dialog.open(AddBotInstanceDialogComponent, {
      width: "500px",
      data: {}
    }).afterClosed().pipe(
    ).subscribe(async (bot: any) => {
      if (bot) {
        this.team.controller.addBot(bot, this.team.id);
      }
    });
  }

  rename() {
    this.dialog.open(DialogQuestionComponent, {
      width: "500px",
      data: {
        title: "Rename the team",
        forms: [{
          label: "Name of the team",
          name: "name",
          type: "string",
          required: true,
          defaultValue: this.team.name
        }]
      }
    }).afterClosed().pipe(
    ).subscribe(async (result: any) => {
      if (result) {
        this.team.rename(result.name);
      }
    });
  }

  addBotFromText() {
    this.dialog.open(DialogQuestionComponent, {
      width: "500px",
      data: {
        title: "Add bots from text",
        forms: [{
          label: "login password per line",
          name: "name",
          type: "textarea",
          required: true,
          defaultValue: ""
        }]
      }
    }).afterClosed().pipe(
    ).subscribe(async (result: any) => {
      if (result) {
        const bots = [];
        for (let line of result.name.split("\n")) {
          if (line == "") continue;
          line = Utils.replaceAll(line, "\t", " ");
          const [login, password] = line.split(" ");
          bots.push({
            alias: login.indexOf("@") != -1 ? login.split("@")[0] : login,
            login: login,
            password: password
          });
        }
        this.team.controller.addBots(bots, this.team.id);
      }
    });
  }

  connectBotsDisconnected() {
    this.team.connectBotsDisconnected();
  }

  connectBots() {
    this.team.connectBots();
  }

  disconnectBots() {
    this.team.disconnectBots();
  }

  copyIds() {
    let str = "";
    for (const bot of this.team.bots) {
      str += bot.login + " " + bot.password + "\n";
    }
    this.clipboard.copy(str);
  }

  async selectThisTeam() {
    let outletRoute = "";
    if (this.userService.teamSelected) {
      const primaryChild = this.route.children.find(elt => elt.outlet == "primary");
      if (primaryChild) {
        const childrenTabs = primaryChild.children.find(elt => elt.outlet == "tabs");
        if (childrenTabs && childrenTabs.snapshot.url.length > 0) {
          outletRoute = childrenTabs.snapshot.url[0].path;
        } else {
          outletRoute = "config";
        }
      }
    }
    if (!outletRoute) {
      outletRoute = "config";
    }
    await this.router.navigateByUrl(`controller/${this.team.controllerId}`);
    this.router.navigateByUrl(`/controller/${this.team.controllerId}/team/${this.team.id}/(tabs:${outletRoute})`);
  }

  removeTeam(withBots: boolean) {
    this.team.controller.removeTeam(this.team.id, withBots);
  }

  removeBots() {
    for (const bot of this.team.bots) {
      this.team.controller.removeBot(bot.login);
    }
  }

  addBotsFromBDD() {
    this.team.addBotsFromBDD(8 - this.team.bots.length);
  }

  createFromConfig() {
    this.team.createFromConfig();
  }


  setState(state: string) {
    for (const bot of this.team.bots) {
      bot.setAccountState(state);
    }
  }

  removeAndSaveBots() {
    for (const bot of this.team.bots) {
      this.team.controller.removeAndSaveBot(bot.login);
    }
  }


  clickOnTeam(event: MouseEvent) {
    if (this.userService.keys[67]) {
      for (const bot of this.team.bots) {
        bot.createFromConfig();
      }
    } else if (this.userService.keys[71]) {
      this.setState("GOOD");
    } else if (this.userService.keys[65]) {
      this.addBotsFromBDD();
    } else if (this.userService.keys[76]) {
      this.setState("LOST");
    } else if (this.userService.keys[83]) {
      this.removeAndSaveBots();
    } else {
      this.team._open = !this.team._open;
    }
  }

  copyIdsAndRemove() {
    this.copyIds();
    this.removeBots();
  }


  editComment() {
    this.dialog.open(DialogQuestionComponent, {
      width: "500px",
      data: {
        title: "Comment",
        forms: [{
          label: "Comment",
          name: "comment",
          type: "textarea",
          required: false,
          defaultValue: this.team.config.comment
        }]
      }
    }).afterClosed().pipe(
    ).subscribe(async (result: any) => {
      if (result) {
        this.team.config.editComment(result.comment);
      }
    });
  }


  diffLastFight() {
    return this.team.lastWinFightDate ? `${Math.floor((Date.now() - this.team.lastWinFightDate) / 1000 / 60).toString()} mins` : "";
  }
}

import {Bot} from "@dashboard/src/app/service/user/controllers/controller/bot";
import {GameFightTurnStartMessage} from "@protocol/messages/GameFightTurnStartMessage";
import {GameFightTurnEndMessage} from "@protocol/messages/GameFightTurnEndMessage";
import {
  LightFighterInformations
} from "@dashboard/src/app/service/user/controllers/controller/bot/game/fight/LightFighterInformations";
import {ArenaRankInfos} from "@protocol/types/ArenaRankInfos";

export class GameFight {
  public turnFighterId: number = -1;
  public allies: LightFighterInformations[] = [];
  public enemies: LightFighterInformations[] = [];
  public lastWinFightDate: number;
  public countFight: number;
  public delayInFight: number;
  public fightsLost: {
    mapid: number,
    mapName: string,
    position: string,
    turnId: number,
    duration: number,
    date: Date
  }[] = [];
  public fightsWin: {
    mapid: number,
    mapName: string,
    position: string,
    turnId: number,
    duration: number,
    date: Date
  }[] = [];
  public arenaRanks: ArenaRankInfos[] = [];
  public arenaRankKoli: ArenaRankInfos = null;
  public coteKoli: number;
  private bot: Bot;

  public constructor(bot: Bot, fight: GameFight) {
    this.bot = bot;
    this.lastWinFightDate = fight.lastWinFightDate;
    this.monitorPackets();
  }

  public resetFightStats() {
    this.bot.send("ResetFIghtStatsRequestMessage", {});
  }


  private monitorPackets() {
    const wrapper = this.bot.wrap();
    wrapper.addMonitorPacket("GameFightTurnStartMessage", (packet: GameFightTurnStartMessage) => {
      this.turnFighterId = packet.id;
    });
    wrapper.addMonitorPacket("GameFightTurnEndMessage", (packet: GameFightTurnEndMessage) => {
      this.turnFighterId = -1;
    });
    wrapper.addMonitorPacket("GeneralFightersInformationsUpdated", (packet: any) => {
      this.allies = packet.allies;
      this.enemies = packet.enemies;
      this.bot.emit("FightersUpdated");
    });
    wrapper.addMonitorPacket("LastWinFightDateUpdated", (packet: any) => {
      this.lastWinFightDate = packet.lastWinFightDate;
    });
    wrapper.addMonitorPacket("FightStatsMessage", (packet: any) => {
      this.countFight = packet.countFight;
      this.delayInFight = packet.delayInFight;
      this.lastWinFightDate = packet.lastWinFightDate;
      this.fightsLost = packet.fightsLost;
      this.fightsWin = packet.fightsWin;
      this.arenaRanks = packet.arenaRanks;
      this.arenaRankKoli = this.arenaRanks.find(elt => elt.arenaType == 3);
    });

  }
}

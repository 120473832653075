countFight : {{ bot.game.fight.countFight }}<br>
delayInFight : {{ bot.game.fight.delayInFight }}<br>
lastWinFightDate : {{ (bot.game.fight.lastWinFightDate * 1000 | date:'dd/MM HH:mm:ss':'fr') }}<br>
Cote : {{ bot.game.fight.arenaRankKoli?.leagueRanking.rating }}<br>
duration moy {{ durationMoy }} secondes <br>
---- FIGHT WIN : {{ bot.game.fight.fightsWin.length }} ----
<div *ngFor="let fight of bot.game.fight.fightsWin">
  {{ '[' + (fight.date | date:'dd/MM':'fr') + '] ' }}
  {{ '[' + (fight.date | date:'HH:mm:ss.SSS':'fr') + '] ' }}
  {{ fight.position + " mapName : " + fight.mapName + " TURN : " + fight.turnId + " duration (s) : " + fight.duration }}
  <br>
</div>
---- FIGHT LOST : {{ bot.game.fight.fightsLost.length }} ----
<div *ngFor="let fight of bot.game.fight.fightsLost">
  {{ '[' + (fight.date | date:'dd/MM':'fr') + '] ' }}
  {{ '[' + (fight.date | date:'HH:mm:ss.SSS':'fr') + '] ' }}
  {{ fight.position + " mapName : " + fight.mapName + " TURN : " + fight.turnId + " duration (s) : " + fight.duration }}
  <br>
</div>
